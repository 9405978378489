const ls = window.localStorage;

export default {
  setUser(user) {
    ls.setItem("vgpaxreg-user", JSON.stringify(user));
  },
  setToken(token) {
    ls.setItem("vgpaxreg-token", token);
  },
  getUser() {
    return JSON.parse(ls.getItem("vgpaxreg-user"));
  },
  getToken() {
    return ls.getItem("vgpaxreg-token");
  },
};
