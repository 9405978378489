import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// panelsVisible determines if the topbar and left navigation should be visible

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: () => import("../views/ForgotPassword.vue"),
    meta: {
      title: "Forgot password",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
    meta: {
      title: "Reset password",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/activate",
    name: "Activate",
    component: () => import("../views/Activate.vue"),
    meta: {
      title: "Activate",
      icon: null,
      panelsVisible: false,
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () => import("../views/Companies.vue"),
    meta: {
      title: "Airports",
      icon: "mdi-airport",
      panelsVisible: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      title: "Users",
      icon: "mdi-account-group",
      panelsVisible: true,
    },
  },
  {
    path: "/",
    name: "Flights",
    component: () => import("../views/Flights.vue"),
    meta: {
      title: "Flights",
      icon: "mdi-airplane-takeoff",
      panelsVisible: true,
    },
  },
  {
    path: "/passengers",
    name: "Passengers",
    component: () => import("../views/Passengers.vue"),
    meta: {
      title: "Passengers",
      icon: "mdi-seat-passenger",
      panelsVisible: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
