import axios from "axios";
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      window.location = "/#/login";
    } else {
      return Promise.reject(error);
    }
  }
);
const API_BASE_URL =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3000/api";

export default {
  // Get info of a user that has not been activated yet
  inactiveUser(email, token) {
    return axios.get(`${API_BASE_URL}/users/inactive/${email}¿${token}`);
  },
  // Activate a user
  activateUser(email, token, name, password) {
    return axios.post(
      `${API_BASE_URL}/users/inactive/${email}¿${token}/activate`,
      {
        name,
        password,
      }
    );
  },
  // Submit email if forgot password
  forgotPassword(email) {
    return axios.post(`${API_BASE_URL}/users/forgotpassword`, { email: email });
  },
  // Initiate password reset with email adress and token received in email
  passwordResetUser(email, token) {
    return axios.get(`${API_BASE_URL}/users/passwordreset/${email}¿${token}`);
  },
  // Set the new password for the user
  resetPassword(email, token, password) {
    return axios.post(`${API_BASE_URL}/users/resetpassword`, {
      email: email,
      token: token,
      password: password,
    });
  },
  // Login
  login(login) {
    return axios.post(`${API_BASE_URL}/users/login`, login);
  },
  // Get list of companies
  getCompanies(token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(`${API_BASE_URL}/companies`, config);
  },
  // Create a new company
  postCompany(token, company) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(`${API_BASE_URL}/companies`, company, config);
  },
  // Edit a company
  putCompany(token, companyId, company) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(`${API_BASE_URL}/companies/${companyId}`, company, config);
  },
  // Get a list of users
  getUsers(token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(`${API_BASE_URL}/users`, config);
  },
  // Create a new user
  postUser(token, user) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.post(`${API_BASE_URL}/users`, user, config);
  },
  // Edit a user
  putUser(token, userId, user) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.put(`${API_BASE_URL}/users/${userId}`, user, config);
  },
  // Delete a user
  deleteUser(token, userId) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.delete(`${API_BASE_URL}/users/${userId}`, config);
  },
  // Get list of passengers
  getPassengers(token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(`${API_BASE_URL}/passengers`, config);
  },
  // Get list of passengers
  getFlights(token) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return axios.get(`${API_BASE_URL}/flights`, config);
  },
};
