import Vue from "vue";
import Vuex from "vuex";
import ls from "../utils/LocalStorage.js";
import api from "../api/api.js";
import { errorMessage } from "../utils/ErrorParser.js";

export const LOADED = "LOADED";
export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const SYSTEM_MESSAGE = "SYSTEM_MESSAGE";
export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_PASSENGERS = "LOAD_PASSENGERS";
export const LOAD_FLIGHTS = "LOAD_FLIGHTS";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loaded: true,
    success: null,
    user: ls.getUser(),
    token: ls.getToken(),
    systemMessage: null,
    forgotPasswordCompleted: null,
    companies: null,
    users: null,
    passengers: null,
    flights: null,
  },
  mutations: {
    [LOADED](state, value) {
      state.loaded = value;
    },
    [LOG_IN](state, payload) {
      state.user = payload.user;
      state.token = payload.token;
      ls.setUser(payload.user);
      ls.setToken(payload.token);
    },
    [LOG_OUT](state) {
      state.user = null;
      state.token = null;
      state.forgotPasswordCompleted = null;
      state.companies = null;
      state.users = null;
      state.passengers = null;
      ls.setUser(null);
      ls.setToken(null);
    },
    [FORGOT_PASSWORD](state, payload) {
      state.forgotPasswordCompleted = payload;
    },
    [SYSTEM_MESSAGE](state, payload) {
      state.systemMessage = payload;
    },
    [LOAD_COMPANIES](state, payload) {
      state.companies = payload;
    },
    [LOAD_USERS](state, payload) {
      state.users = payload;
    },
    [LOAD_PASSENGERS](state, payload) {
      state.passengers = payload;
    },
    [LOAD_FLIGHTS](state, payload) {
      state.flights = payload;
    },
  },
  actions: {
    async login({ commit }, login) {
      commit(LOADED, false);
      try {
        const result = await api.login(login);
        commit(LOG_IN, result.data);
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: error.response.data.message,
        });
        commit(LOADED, true);
      }
    },
    logout({ commit }) {
      commit(SYSTEM_MESSAGE, null);
      commit(LOG_OUT);
    },
    async forgotPassword({ commit }, email) {
      commit(LOADED, false);
      try {
        await api.forgotPassword(email);
        commit(FORGOT_PASSWORD, email);
        commit(SYSTEM_MESSAGE, null);
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async loadCompanies({ commit, state }) {
      commit(LOADED, false);
      try {
        const result = await api.getCompanies(state.token);
        if (result) {
          commit(LOAD_COMPANIES, result.data);
        }
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async createCompany({ commit, state }, company) {
      commit(LOADED, false);
      try {
        await api.postCompany(state.token, company);
        const result = await api.getCompanies(state.token);
        if (result) {
          commit(LOAD_COMPANIES, result.data);
        }
        commit(SYSTEM_MESSAGE, {
          type: "success",
          text: "Airport created",
        });
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async saveCompany({ commit, state }, submission) {
      commit(LOADED, false);
      try {
        await api.putCompany(
          state.token,
          submission.companyId,
          submission.company
        );
        const result = await api.getCompanies(state.token);
        if (result) {
          commit(LOAD_COMPANIES, result.data);
        }
        commit(LOADED, true);
        commit(SYSTEM_MESSAGE, {
          type: "success",
          text: "Airport updated",
        });
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async loadUsers({ commit, state }) {
      commit(LOADED, false);
      try {
        const result = await api.getUsers(state.token);
        if (result) {
          commit(LOAD_USERS, result.data);
        }
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async createUser({ commit, state }, user) {
      commit(LOADED, false);
      try {
        await api.postUser(state.token, user);
        const result = await api.getUsers(state.token);
        if (result) {
          commit(LOAD_USERS, result.data);
        }
        commit(SYSTEM_MESSAGE, {
          type: "success",
          text: "Bruger oprettet",
        });
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async saveUser({ commit, state }, submission) {
      commit(LOADED, false);
      try {
        await api.putUser(state.token, submission.userId, submission.user);
        const result = await api.getUsers(state.token);
        if (result) {
          commit(LOAD_USERS, result.data);
        }
        commit(SYSTEM_MESSAGE, {
          type: "success",
          text: "Bruger opdateret",
        });
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async deleteUser({ commit, state }, userId) {
      commit(LOADED, false);
      try {
        await api.deleteUser(state.token, userId);
        const result = await api.getUsers(state.token);
        if (result) {
          commit(LOAD_USERS, result.data);
        }
        commit(SYSTEM_MESSAGE, {
          type: "success",
          text: "Bruger slettet",
        });
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async loadFlights({ commit, state }) {
      commit(LOADED, false);
      try {
        const result = await api.getFlights(state.token);
        if (result) {
          commit(LOAD_FLIGHTS, result.data);
        }
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
    async loadPassengers({ commit, state }) {
      commit(LOADED, false);
      try {
        const result = await api.getPassengers(state.token);
        if (result) {
          commit(LOAD_PASSENGERS, result.data);
        }
        commit(LOADED, true);
      } catch (error) {
        commit(SYSTEM_MESSAGE, {
          type: "error",
          text: errorMessage(error),
        });
        commit(LOADED, true);
      }
    },
  },
  modules: {},
});
