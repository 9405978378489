<template>
  <v-app>
    <v-snackbar
      class="mt-12"
      v-model="messageVisible"
      top
      :color="(systemMessage && systemMessage.type) || 'primary'"
      :timeout="(systemMessage && systemMessage.timeout) || 4000"
    >
      {{ systemMessage && systemMessage.text }}
    </v-snackbar>
    <v-navigation-drawer permanent app v-if="$route.meta.panelsVisible">
      <v-list-item>
        <v-list-item-content> </v-list-item-content>
      </v-list-item>

      <v-list>
        <v-list-item
          v-for="(item, i) in userNavItems"
          :key="i"
          @click="navItemClick(item)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      v-if="$route.meta.panelsVisible"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
      height="68px"
      flat
    >
      <v-icon class="mr-3">{{ $route.meta.icon }}</v-icon>
      <h3>{{ $route.meta.title }}</h3>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      messageVisible: false,
      selectedNav: 0,
      navItems: [
        {
          text: "Flights",
          icon: "mdi-airplane-takeoff",
          path: "/",
        },
        {
          text: "Passengers",
          icon: "mdi-seat-passenger",
          path: "/passengers",
        },
        {
          text: "Airports",
          icon: "mdi-airport",
          admin: true,
          path: "/companies",
        },
        {
          text: "Users",
          icon: "mdi-account-group",
          admin: true,
          path: "/users",
        },
        {
          text: "Log out",
          icon: "mdi-logout",
          path: null,
          method: this.logOut,
        },
      ],
    };
  },
  watch: {
    systemMessage: function (newVal) {
      if (newVal) {
        this.messageVisible = true;
      } else {
        this.messageVisible = false;
      }
    },
  },
  computed: {
    ...mapState(["systemMessage", "user"]),
    routeName() {
      return this.$route.name;
    },
    userNavItems() {
      if (this.user) {
        return this.navItems.filter((item) => {
          return (
            (!item.admin && !item.submitter) ||
            (this.user.company.admin && item.admin) ||
            (this.user.submitter && item.submitter)
          );
        });
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["logout"]),
    navItemClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else if (item.method) {
        item.method();
      }
    },
    logOut() {
      this.$router.replace("/login");
      this.logout();
    },
  },
};
</script>
